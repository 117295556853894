.webcam-header {
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 1;
}

.webcam-header-elements {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.webcam-header-elements button {
    width: 32px;
    height: 32px;
    background: none;
    border: none;
    position: fixed;
    left: 8px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.webcam-header-elements button figure {
    width: 12px;
}

.webcam-header-elements span {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.webcam-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 130px;
    background-color: #FFFFFF;
    opacity: 0.9;
}

.webcam-footer-controls-before-capture {
    display: flex;
    justify-content: space-around; 
    align-items: center;
    height: 100%;
}

.webcam-footer-controls-after-capture {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px;
}

.webcam-no-image {
    background-color: #ABBED1;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.webcam-header-view {
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    opacity: 0.9;
    z-index: 1;
}

.icon-button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}