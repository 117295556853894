.yta-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.yta-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.yta-btn-symbol {
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    margin: 6px;
    flex-wrap: wrap;
}

.yta-row .yta-column:last-child .yta-btn-symbol:last-child {
    margin-right: 0;
}

.yta-row .yta-column:first-child .yta-btn-symbol:first-child {
    margin-left: 0;
}

@media screen and (orientation: landscape) {
    body {
        position: relative;
    }
    
    .yta-screen {
        background-color: #fff;
        position: fixed;
        width: 100vw;
        height: 100vh;
        overflow:hidden;
        left: 0;
        top: 0;
        padding: 6px;
    }
}
