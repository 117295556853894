*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
  font-size: 16px;
}

.main {
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}

.text-none-transform {
  text-transform: none !important;
}

/*** Autocomplete ***/
.autocomplete-container {
  position: relative;
}
.autocomplete-suggestions {
  position: absolute;
  z-index: 999;
  width: 100%;
}

/* sticky-alert */
.sticky-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.fade-in {
  animation: fadein 100ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeout 50ms;
}

@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.btn-restart-redux {
  position: fixed;
  bottom: 10px;
  right: 148px;
  z-index: 999;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

label {
  font-size: 14px;
  margin-bottom: 4px;
}

.button-take-photo {
  background: #06F;
    color: #fff;
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: block;
    border-radius: 50%;
    text-align: center;
    text-decoration:none;
    border:3px solid #fff;
    box-shadow: 0px 0px 0px 3px #06F;
}