.form-control {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(171, 190, 209);
  border-radius: 4px;
  color: rgb(48, 48, 50);
  padding: 8px 10px;
}

.form-control:hover,
.form-control:focus {
  outline: none;
  border-width: 3px;
  border-color: rgb(171, 190, 209);
  padding: 7px 9px;
  box-shadow: none;
}

.form-control::placeholder {
  color: rgb(212, 212, 212);
}

.form-control.is-validating {
  border-color: #978080 !important;
}

.form-group.has-validation small.invalid-feedback,
.form-group.has-validation small.valid-feedback {
  display: block;
}

.form-group div.react-datepicker-wrapper {
  display: block;
}

.form-group {
  margin-bottom: 15px;
}

.form-preview-input {
  border: none;
  padding: 0;
  font-weight: bold;
}

.preview-item span {
  font-weight: bold;
  display: block;
  font-size: 18px;
}

.success-screen {
  text-align: center;
}

.success-screen img {
  height: 100px;
  margin-top: 20px;
}

.success-screen p {
  margin-top: 20px;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.is-valid {
  border-color: #74ec81 !important;
}
